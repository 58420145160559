.description {
  font-size: 16px;
  margin-bottom: 40px;
}

.list-alerts {
  p {
    font-size: 14px;
    line-height: 20px;
  }

  .header {
    padding: 10px 20px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr 3fr 1.5fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    @media (max-width: 620px) {
      grid-template-columns: 1fr;
    }
  }
  .headerAlerta {
    padding: 10px 20px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr 1fr 3fr 1.5fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    position: sticky;
    top: 0px;
    background-color: #ececec;
    @media (max-width: 620px) {
      grid-template-columns: 1fr;
    }
  }
  .item {
    background: #f7f7f7;
    padding: 10px 20px;
    margin-bottom: 20px;
    font-size: 14px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr 1fr 3fr 1.5fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    cursor: pointer;
    transition: all ease 0.3s;
    border-radius: 4px;
    &:hover {
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    }
    @media (max-width: 620px) {
      grid-template-columns: 1fr;
    }
    .date {
      &.altered {
        color: red;
      }
      &.current {
        color: #666;
      }
    }

    p.responsible {
      text-transform: capitalize;
    }
  }
  .box-count {
    padding: 2px 10px;
    border-radius: 5px;
    background: #e74c3c;
    background: #e74c3c;
    background: linear-gradient(90deg, #e74c3c 0%, rgba(253, 29, 29, 1) 100%);
    display: flex;
    align-items: center;
    width: auto;
    justify-content: center;
    display: inline-block;
    font-size: 12px;
    color: #fff;
    margin: 0 10px;
  }
}

.result-alerts {
  margin: -20px 0 20px;
  font-size: 12px;
  display: flex;
  span {
    padding: 0 20px;
    border-right: solid 2px #ccc;
    &:first-child {
      strong {
        color: black;
      }
    }
    strong {
      color: #e74c3c;
    }
    &:last-child {
      border: 0;
      strong {
        color: #1abc9c;
      }
    }
  }
}
