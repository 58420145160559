.main-header{
    width: 100%;
    height: 70px;
    background: #292D41;
    margin-bottom: 40px;
    .center{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }
    .main-nav{
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        @media (max-width: 520px){
            display: none;
        }
        a{
            font-size: 14px;
            color: #8B8D97;
            transition: all ease 0.3s;
            &:hover, &.active{
                color: #fff;
            }
        }
    }
    .user-header{
        width: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .icon-menu{
            margin-left: 10px;
        }
    }
}

.icon-menu{
    cursor: pointer;
    span{
        width: 16px;
        height: 3px;
        background: #8B8D97;
        display: block;
        border-radius: 4px;
        margin-bottom: 3px;
        transition: all ease 0.3s;
        &:nth-child(2n){
            width: 15px;
        }
        
    }
    &:hover{
        span{
            background: #fff;
        }
    }
}