@font-face {
  font-family: 'CircularStd';
  src: url('/css/font/CircularStd-Medium.eot') format('embedded-opentype'),  url('/css/font/CircularStd-Medium.woff') format('woff'), url('/css/font/CircularStd-Medium.ttf')  format('truetype'), url('/css/font/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'CircularStd';
  src: url('/css/font/CircularStd-Black.eot?#iefix') format('embedded-opentype'),  url('/css/font/CircularStd-Black.woff') format('woff'), url('/css/font/CircularStd-Black.ttf')  format('truetype'), url('/css/font/CircularStd-Black.svg#CircularStd-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('/css/font/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),  url('/css/font/CircularStd-BlackItalic.woff') format('woff'), url('/css/font/CircularStd-BlackItalic.ttf')  format('truetype'), url('/css/font/CircularStd-BlackItalic.svg#CircularStd-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('/css/font/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),  url('/css/font/CircularStd-Bold.woff') format('woff'), url('/css/font/CircularStd-Bold.ttf')  format('truetype'), url('/css/font/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('/css/font/CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),  url('/css/font/CircularStd-BoldItalic.woff') format('woff'), url('/css/font/CircularStd-BoldItalic.ttf')  format('truetype'), url('/css/font/CircularStd-BoldItalic.svg#CircularStd-BoldItalic') format('svg');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'CircularStd';
  src: url('/css/font/CircularStd-Book.eot?#iefix') format('embedded-opentype'),  url('/css/font/CircularStd-Book.woff') format('woff'), url('/css/font/CircularStd-Book.ttf')  format('truetype'), url('/css/font/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('/css/font/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),  url('/css/font/CircularStd-BookItalic.woff') format('woff'), url('/css/font/CircularStd-BookItalic.ttf')  format('truetype'), url('/css/font/CircularStd-BookItalic.svg#CircularStd-BookItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}
