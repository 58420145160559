.list-ope{
    p{
        font-size: 12px;
        padding: 0 5px;
        color: #4C5062;
    }
    .header-list-ope{
        position: sticky;
        top: 0;
        background-color: #ececec;
        height: 40px;
        align-items: center;
        z-index: 1;
        display: grid;
        grid-template-columns: .6fr 2fr 2fr 4fr 2fr 2fr 2fr 2fr 2fr 2fr;
        margin-bottom: 5px;
        p{
            font-size: 14px;
        }
        @media (max-width: 740px){
            grid-template-columns: .6fr 2fr 2fr  2fr 2fr 1fr;
            .produto, .descricao, .qtd, .pd{
                display: none;
            }
        }

    }
    .item{
        background: #fff;
        border-radius: 6px;
        align-items: center;
        margin-bottom: 20px;
        padding: 15px 0;
        transition: .2s;
        display: grid;
        grid-template-columns: .6fr 2fr 2fr 4fr 2fr 2fr 2fr 2fr 2fr 2fr;
        height: auto;
        transition: .2s;
        cursor: pointer;
        &:hover{
            box-shadow: 1px 10px 10px rgba($color: #000000, $alpha: 0.1)
        }
        span.critico{
            border-left: solid 10px #1ABC9C;
            display: block;
            border-radius: 6px 0 0 6px;
            height: 100%;
            padding: 15px 0;
        }
        .status{
            position: relative;
            display: flex;
            align-items: center;

            // &:before{
            //     content: '';
            //     width: 10px;
            //     height: 10px;
            //     background: #1ABC9C;
            //     display: block;
            //     margin-right: 10px;
            // }
            &.alert{
                &:before{
                    background: #F3D641;
                }
            }
            &.danger{
                &:before{
                    background: #d25b5b;
                }
            }
            @media (max-width: 740px){
                p{
                    display: none;
                }
            }
        }
        .icon-justificativa{
            cursor: pointer;
            background: url('../img/icon-justificativa.png')no-repeat;
            width: 15px;
            height: 15px;
            margin-left: 10px;
            &.ativa{
                background: url('../img/icon-justificativa-ativa.png')no-repeat;
            }
            &.ok{
                background: url('../img/icon-justificativa-ok.png')no-repeat;
            }
        }
        @media (max-width: 740px){
            grid-template-columns: .6fr 2fr 2fr  2fr 2fr 1fr;
            .produto, .descricao, .qtd, .pd{
                display: none;
            }
            p{
                font-size: 10px;
            }
        }
        &.yes{
          span.critico{
            border-left: solid 10px #E99898;
            display: block;
            border-radius: 6px 0 0 6px;
            height: 100%;
            padding: 15px 0;
        }
        }
        &.red{
          background: #fdb4b4;
        }
        &.yellow{
          background-color: #ffebb0;
        }
    }
}

.lb-justificativa{
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  h2{
      font-size: 22px;
      margin-bottom: 40px;
  }
  .content{
      width: 70%;
      background: #fff;
      padding: 40px 60px;
  }
  .wrap-btns{
      width: 30%;
      background: #ccc;
      position: relative;
      padding: 40px 0;
      .btnclose{
          position: absolute;
          top: 10px;
          right: 20px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #4B4E60;
          color: #fff;
          cursor: pointer;
          font-size: 14px;

      }
  }
  .form-just{
      label{
          display: block;
          margin-bottom: 10px;
          font-size: 14px;
      }
      input, select, textarea{
          width: 100%;
          height: 50px;
          padding: 0 20px;
          border: 0;
          border: solid 1px #C2C2C2;
          box-sizing: border-box;
          border-radius: 6px;
      }
      textarea{
          height: 100px;
          resize: none;
          outline: none;
          padding: 20px;
      }
      .row{
          display: grid;
          margin-bottom: 20px;
          &.c2{
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 20px;
          }
          .item{
              width: 100%;
          }
      }
      .btn{
          height: 50px;
          &.cancel{
            margin-left: 20px;
          }
      }
      .erro{
        color: #FF4E50;
        font-size: 12px;
        margin: 5px 0;
      }
  }
  .list-justificativas{
      .item{
          border-bottom: solid 1px #292D41;
          padding-bottom: 20px;
          margin-bottom: 20px;
          &:last-child{
              border-bottom: none;
          }
          p{
              font-size: 14px;
              color: #292D41;
              line-height: 20px;
              margin-bottom: 20px;
          }
          .user{
              display: flex;
              align-items: center;
              p{
                  font-weight: bold;
                  margin-right: 20px;
                  margin-bottom: 0;
                  &:last-child{
                      margin-right: 0;
                  }
              }
              input[type="checkbox"]{
                  width: 18px;
                  height: 18px;
                  appearance: none;
                  padding: 0;
                  border: solid 2px #dac9c9;
                  background-position: center;
                  cursor: pointer;
                  margin-right: 20px;
                  &:checked{
                          background: url(../img/icons/icon-check.png)no-repeat center;
                          border: solid 2px #292D41;
                  }
              }
          }
      }
  }

  .wrap-btns{
      background: #292D41;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      button{
          width: 120px;
          height: 50px;
          margin-bottom: 10px;
          transition: all ease 0.1s;
          &.abonar{
              margin-bottom: 60px;
              background: #1AB1BC;
          }
          &:hover{
              background: transparent;
              border: solid 1px #fff;
          }
      }
  }
  @media (max-width: 740px){
    flex-wrap: wrap;
    .wrap-btns{
      order: 1;
      width: 100%;
      padding: 20px 0;
      button.abonar{
        margin-bottom: 20px;
      }
    }
    .content{
      order: 2;
      width: 100%;
    }
  }
}

.totalope{
    font-size: 12px;
    margin-bottom: 20px;
}

.favorite{
    width: 20px;
    padding: 7px;
    &.not{
      filter: grayscale(1);
      opacity: 0.6;
    }
    &:hover{
      filter: grayscale(0.2);
      transform: scale(1.08);
    }
  }
  